<template>
  <div class="container">
    <h1><i class="fa fa-file-video-o"></i> new films</h1>

    <div v-for="film in filmlist" :key="film._id">
      <router-link :to="`edit-film/${film._id}`">{{film.name}}</router-link>
    </div>

  </div>
</template>


<script>
import axios from "axios";

export default {

  name: "AdminNewFilms",

  data() {
    return {
      filmlist: []
    }
  },

  async created() {
    this.filmlist = [];

    axios.get(`/api/admin/new-films`)
        .then((response) => {
          this.filmlist = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
  },

  methods: {

  }


}
</script>


<style scoped>

</style>