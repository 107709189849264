<template>
  <div class="modal fade" id="film-or-category-modal" tabindex="-1" role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <!-- film header -->
          <FilmCheckbox v-if="filmInfo" :film_id="filmInfo.id"></FilmCheckbox>
          <h6 class="modal-title">
            {{titleText}}<span class="bookmark"><FilmBookmark v-if="filmInfo" :film_id="filmInfo.id"></FilmBookmark></span>
          </h6>

          <!-- X button for closing dialog -->
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>

        <div class="modal-body">
          <ModalBodyForFilm v-if="filmInfo" :film_name="filmInfo.name" :film_id="filmInfo.id" @show-category="setCategoryInfo" @edit-film="editFilm"></ModalBodyForFilm>
          <ModalContentForCategory v-if="categoryInfo" :category_name="categoryInfo.name" :ceremony="categoryInfo.ceremony" @show-film="setFilmInfo"></ModalContentForCategory>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import {Modal} from "bootstrap";
import ModalBodyForFilm from "@/components/modal/ModalBodyForFilm.vue";
import ModalContentForCategory from "@/components/modal/ModalBodyForCategory.vue";
import FilmCheckbox from "@/components/FilmCheckbox.vue";
import {Film} from "@/film";
import FilmBookmark from "@/components/FilmBookmark.vue";

export default {
  name: "FilmOrCategoryModal",
  components: {FilmBookmark, FilmCheckbox, ModalContentForCategory, ModalBodyForFilm},
  props: ['category_name', 'ceremony', 'film_name', 'film_id'],
  emits: ['modal-closed'],
  computed: {
    titleText() {
      if ( this.filmInfo ) {
        return this.filmInfo.name;
      } else if ( this.categoryInfo ) {
        return `${this.categoryInfo.name} | ${Film.getCeremonyHeaderText(this.categoryInfo.ceremony)}`;
      }
      return '';
    },
  },

  data() {
    return {
      categoryInfo: null,
      filmInfo: null
    }
  },

  mounted() {

    this.setCategoryInfo(this.category_name, this.ceremony);
    this.setFilmInfo(this.film_id, this.film_name);

    const theModal = new Modal('#film-or-category-modal', {});
    theModal.show();
    document.getElementById('film-or-category-modal')
        .addEventListener('hidden.bs.modal', this.onModalClosed);
  },

  methods: {
    closeModal() {
      const modalElement = document.getElementById('film-or-category-modal');
      const modal = Modal.getInstance(modalElement);
      modal.hide();
    },
    onModalClosed() {
      this.$emit('modal-closed', null);
    },
    setFilmInfo(_id, _name) {
      //console.log(`setFilmInfo(${_id}, ${_name}, ${_year})`);
      if ( _id && _name ) {
        this.filmInfo = {id: _id, name: _name};
        this.categoryInfo = null;
        return true;
      }
      return false;
    },
    setCategoryInfo(_name, _ceremony) {
      //console.log(`setCategoryInfo(${_name}, ${_year})`);
      if ( _name && Number.isInteger(_ceremony) ) {
        this.categoryInfo = {name: _name, ceremony: _ceremony};
        this.filmInfo = null;
        return true;
      }
      return false;
    },
    editFilm(_filmId) {
      this.closeModal();
      this.$router.push({name: 'AdminEditFilm', params: {filmId: _filmId}});
    }

  }
}
</script>

<style scoped>

  .modal-dialog {
    line-height: var(--bs-body-line-height);
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    text-indent: 0;

    .modal-body {
      font-size: small;
    }
  }

  .modal-title {
    padding-left: 10px;

    .bookmark {
      padding-left: 0.2em;
    }
  }

</style>