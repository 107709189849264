<template>
  <div class="container">
    <div class="page-header">
      <h1><i class="fa fa-file-video-o"></i> edit film</h1>
      <hr>
    </div>

    <div v-if="film" class="row">

      <!--    our film info, similar to the film modal, but with a full printout of the mongo object included -->
      <div class="col-6">
        <h3 class="text-center">our database</h3>
        <div class="card">
          <div class="card-header monospace">
            id: {{film._id}}
          </div>
          <div class="card-body">
            <div class="row">
              <div style="margin-bottom: 1em;">
                <div class="card-film-title"><em>"{{film.name}}"</em> ({{film.year}})</div>
                <img class="poster-image" :src="film.poster_url" >
                <LanguageIcon class="language-icon" v-if="film.original_language !== 'en'" :language_code="film.original_language" :height="15"></LanguageIcon>
                <span style="font-weight: 500;">{{ film.genreText(' • ') }} • {{ film.runtimeText() }}</span>
                <div>{{film.tmdb?.overview}}</div>
                <div class="online-source-links">
                  <a :href="`https://www.themoviedb.org/${film.tmdb?.mediatype}/${film.tmdb?.id}`" title='TMDB' target='blank'>
                    https://www.themoviedb.org/{{tmdb_lookup.params.tmdb_mediatype}}{{tmdb_lookup.params.tmdb_id}}<sup><i class="fa fa-external-link"></i></sup>
                  </a>
                  <a :href="film.imdbLink()" title='IMDB' target='blank'>
                    {{film.imdbLink()}}<sup><i class="fa fa-external-link"></i></sup>
                  </a>
                </div>
              </div>
              <hr>
              <div class="nomination-list">
                <ul> <!-- nomination list -->
                  <li v-for="(nomination, index) in film.nominations" :key="index" :class="nomination.won? 'li_win' : 'li_nom'">
                    <small>
                      {{ nomination.category.name }} | {{getCeremonyText(nomination.ceremony)}}
                      <br><i>{{ nomination.nominee }}</i>
                    </small>
                  </li>
                </ul>
              </div>
              <hr>
              <div class="api-response">
                <h5>database document</h5>
                <pre>{{JSON.stringify(film, null, 2)}}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--    various sources where we find this film's data online -->
      <div class="col-6">
        <h3 class="text-center">online sources</h3>

        <!--    gracenote -->
        <div v-if="film.gracenote" class="card card-gracenote">
          <div class="card-header monospace">
            gracenote<span v-if="gracenote_lookup.response"> onConnect rootId: {{gracenote_lookup.params.onconnect_rootid}}</span>
          </div>
          <div v-if="gracenote_lookup.response" class="card-body">
            <div class="row">
              <div style="margin-bottom: 1em;">
                <img class="poster-image" :src="`https://demo.tmsimg.com/${gracenote_lookup.response.preferredImage.uri}`" >
                <LanguageIcon class="language-icon" v-if="shouldDisplayLanguage(gracenote_lookup.response.titleLang)" :language_code="gracenote_lookup.response.titleLang" :height="15"></LanguageIcon>
                <span style="font-weight: 500;">{{ gracenoteGenreText }} • {{ gracenoteRuntimeText }}</span>
                <div>{{gracenote_lookup.response.longDescription}}</div>
              </div>
              <hr>
              <div class="nomination-list">
                <ul> <!-- nomination list -->
                  <li v-for="(award, index) in gracenoteAcademyAwards" :key="index" :class="award.won? 'li_win_bw' : 'li_nom_bw'">
                    <small>
                      {{ award.category }} | {{award.year}}
                      <br><i v-if="award.recipient">{{ award.recipient }}</i>
                    </small>
                  </li>
                </ul>
              </div>
              <hr>
              <div class="api-response">
                <h5 class="monospace">onConnect api response</h5>
                <pre>{{JSON.stringify(gracenote_lookup.response, null, 2)}}</pre>
              </div>
            </div>
          </div>
        </div>

        <!--    tmdb -->
        <div class="card card-tmdb">
          <div class="card-header monospace">
            tmdb<span v-if="tmdb_lookup.response"> id: {{tmdb_lookup.params.tmdb_mediatype}}/{{tmdb_lookup.params.tmdb_id}}</span>
            <button v-if="tmdbShowUpdateFilmButton" class="btn btn-sm btn-outline-danger btn-update-film" type="button" @click="updateFilmTmdb(tmdb_lookup.params.tmdb_mediatype, tmdb_lookup.params.tmdb_id)">
              udpate film
            </button>
          </div>
          <div class="card-body">
            <div v-if="tmdb_lookup.response" class="row">
              <div style="margin-bottom: 1em;">
                <div class="card-film-title"><em>"{{tmdb_lookup.response.name || tmdb_lookup.response.title}}"</em> ({{tmdbYear(tmdb_lookup.response.release_date || tmdb_lookup.response.first_air_date)}})</div>
                <img class="poster-image" :src="`https://image.tmdb.org/t/p/w200/${tmdb_lookup.response.poster_path}`" >
                <LanguageIcon class="language-icon" v-if="shouldDisplayLanguage(tmdb_lookup.response.original_language)" :language_code="tmdb_lookup.response.original_language" :height="15"></LanguageIcon>
                <span style="font-weight: 500;">{{ tmdbGenreText(tmdb_lookup.response.genres) }} • {{ tmdbRuntimeText(tmdb_lookup.response.runtime) }}</span>
                <div>{{tmdb_lookup.response.overview}}</div>
                <div class="online-source-links">
                  <a :href="`https://www.themoviedb.org/${tmdb_lookup.params.tmdb_mediatype}/${tmdb_lookup.response.id}`" title='TMDB' target='blank'>
                    https://www.themoviedb.org/<b>{{tmdb_lookup.params.tmdb_mediatype}}</b>/<b>{{tmdb_lookup.params.tmdb_id}}</b><sup><i class="fa fa-external-link"></i></sup>
                  </a>
                  <a v-if="tmdb_lookup.response.imdb_id" :href="`https://www.imdb.com/title/${tmdb_lookup.response.imdb_id}`" title='IMDB' target='blank'>
                    https://www.imdb.com/title/<b>{{tmdb_lookup.response.imdb_id}}</b><sup><i class="fa fa-external-link"></i></sup>
                  </a>
                </div>
              </div>
              <hr>
              <div class="api-response">
                <h5 class="monospace">tmdb api response</h5>
                <pre>{{JSON.stringify(tmdb_lookup.response, null, 2)}}</pre>
              </div>
            </div>


            <div class="row">
              <div v-if="tmdbAlternativeOptions?.length">
                <h5>alternate options</h5>

<!--                <pre>{{JSON.stringify(tmdbAlternativeOptions, null, 2)}}</pre>-->
                <div v-for="(searchResult) in tmdbAlternativeOptions" :key="searchResult.id">

<!--                   show each film like the main tmdb display-->
                  <div class="card card-tmdb">
                    <div class="card-header monospace">
                      tmdb<span> id: {{searchResult.mediatype}}/{{searchResult.id}}</span>
                      <button class="btn btn-sm btn-outline-primary btn-use-tmdb" type="button" @click="useTmdb(searchResult.mediatype, searchResult.id)">
                        use
                      </button>
                    </div>
                    <div v-if="searchResult" class="card-body">
                      <div class="row">

                        <div style="margin-bottom: 1em;">
                          <div class="card-film-title">
                            <em>"{{searchResult.name || searchResult.title}}"</em> ({{tmdbYear(searchResult.release_date || searchResult.first_air_date)}})
                            <span v-if="searchResult.original_title && searchResult.original_title !== searchResult.title"><br>aka <em>"{{searchResult.original_title}}"</em></span>
                          </div>
                          <img class="poster-image" :src="`https://image.tmdb.org/t/p/w200/${searchResult.poster_path}`" >
                          <LanguageIcon class="language-icon" v-if="shouldDisplayLanguage(searchResult.original_language)" :language_code="searchResult.original_language" :height="15"></LanguageIcon>
                          <span style="font-weight: 500;">{{ tmdbGenreText(searchResult.genres) }}</span>
                          <div>{{searchResult.overview}}</div>
                          <div class="online-source-links">
                            <a :href="`https://www.themoviedb.org/${searchResult.mediatype}/${searchResult.id}`" title='TMDB' target='blank'>
                              https://www.themoviedb.org/<b>{{searchResult.mediatype}}</b>/<b>{{searchResult.id}}</b><sup><i class="fa fa-external-link"></i></sup>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

              </div>
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
</template>


<script>
import axios from "axios";
import {Film} from "@/film";
import LanguageIcon from "@/components/LanguageIcon.vue";

export default {

  name: "AdminEditFilm",
  components: {LanguageIcon},
  props: ['filmId'],

  data() {
    return {
      film: null,
      tmdb_lookup: {params: null, response: null, nameSearchResults: null},
      gracenote_lookup: {params: null, response: null},
    }
  },

  async created() {

    // get the film from our db
    axios.get(`/api/admin/film/${this.filmId}`)
        .then((response) => {
          this.film = response?.data? new Film(response.data) : null;

          // get tmdb film data
          this.tmdb_lookup.params = {
            tmdb_id: this.film.tmdb?.id,
            tmdb_mediatype: this.film.tmdb?.mediatype
          };

          // get the tmdb data associated with our film
          return this.film?.tmdb?.id ? axios.get(`/api/admin/tmdb-data`, {
            params: this.tmdb_lookup.params
          }) : null;
        })
        .then((response) => {
          this.tmdb_lookup.response = response?.data;

          // get some alternative tmdb results
          return this.tmdbNameSearch(this.film.name);
        })
        .then((response) => {
          this.tmdb_lookup.nameSearchResults = response;

          // get gracenote onConnect film data
          this.gracenote_lookup.params = {
            onconnect_rootid: this.film.gracenote?.onConnect?.rootId
          };
          return this.film?.gracenote?.onConnect?.rootId ? axios.get(`/api/admin/gracenote-data`, {
            params: this.gracenote_lookup.params
          }) : null;
        })
        .then((response) => {
          this.gracenote_lookup.response = response?.data;
        })
        .catch((err) => {
          console.log(err);
        });
  },

  computed: {

    tmdbAlternativeOptions() {
      if ( !Array.isArray(this.tmdb_lookup?.nameSearchResults) ) {
        return [];
      }
      return this.tmdb_lookup.nameSearchResults.filter((searchResult) => {
        return searchResult.id !== this.tmdb_lookup?.response?.id;
      });
    },

    tmdbShowUpdateFilmButton() {

      // if there IS a tmdb lookup response, but it does NOT match our film's show the 'update film' button
      return (this.tmdb_lookup?.response &&
          (
              ( this.tmdb_lookup.params.tmdb_id !== this.film.tmdb?.id ) ||
              ( this.tmdb_lookup.params.tmdb_mediatype !== this.film.tmdb?.mediatype )
          )
      );
    },

    gracenoteGenreText() {
      if ( !this.gracenote_lookup.response?.genres?.length ) {
        return "";
      }
      return this.gracenote_lookup.response.genres.join(' • ');
    },
    gracenoteRuntimeText() {
      if ( !this.gracenote_lookup.response?.runTime ) {
        return "";
      }
      // runTime	number	duration, specified in ISO-8601 format; PTxxHyyM = xx hours, yy minutes
      // "runTime": "PT02H34M",
      const hours = Number(this.gracenote_lookup.response.runTime.slice(2,4));
      const minutes = Number(this.gracenote_lookup.response.runTime.slice(5,7));
      return ( (hours > 0)? `${hours}h ${minutes}m` : `${minutes}m` );
    },
    gracenoteAcademyAwards() {
      if ( !this.gracenote_lookup.response?.awards?.length ) {
        return [];
      }
      return this.gracenote_lookup.response?.awards.filter((award) => {
        return ( award.name === 'Academy Award' );
      });
    }
  },

  methods: {

    tmdbGenreText(_genres) {
      if ( !_genres?.length ) {
        return "";
      }
      return _genres.map(genre => genre.name).join(' • ');
    },
    tmdbRuntimeText(_runtime) {
      if ( !_runtime ) {
        return "";
      }
      const hours = Math.floor(_runtime / 60);
      const minutes = _runtime % 60;
      return ( (hours > 0)? `${hours}h ${minutes}m` : `${minutes}m` );
    },
    tmdbYear(_releaseDate) {
      if ( !_releaseDate ) {
        return "";
      }
      return _releaseDate.slice(0,4);
    },
    getCeremonyText(_ceremony) {
      return Film.getCeremonyHeaderText(_ceremony);
    },
    shouldDisplayLanguage(_langCode) {
      if ( !_langCode?.length ) {
        return false;
      }
      // display language codes that are either non-english or don't agree with our db version
      return (
          (_langCode !== 'en') ||
          (_langCode !== this.film.original_language)
      );
    },
    async tmdbNameSearch(_filmName) {

      let result = [];

      // get results from a 'movie' search
      return axios.get(`/api/admin/tmdb-search`, {
        params: {
          title: _filmName,
          mediatype: 'movie'
        }
      })
          .then((response) => {
            if ( Array.isArray(response?.data) ) {
              result.push( ...(response?.data) );
            }

            // get results from a 'tv' seach
            return axios.get(`/api/admin/tmdb-search`, {
              params: {
                title: _filmName,
                mediatype: 'tv'
              }
            });
          })
          .then((response) => {
            if (Array.isArray(response?.data)) {
              result.push(...(response?.data));
            }

            return result;
          })

          .catch((err) => {
            console.log(err);
          });

    },
    async useTmdb(_mediaType, _tmdbId) {
      console.log(`useTmdb(${_mediaType}, ${_tmdbId})`);

      // get tmdb film data
      this.tmdb_lookup.params = {
        tmdb_id: _tmdbId,
        tmdb_mediatype: _mediaType
      };

      return axios.get(`/api/admin/tmdb-data`, {
        params: this.tmdb_lookup.params
      })
          .then((response) => {
            this.tmdb_lookup.response = response?.data;
          })
          .catch((err) => {
            console.log(err);
          });
    },
    async updateFilmTmdb(_mediaType, _tmdbId) {
      console.log(`updateFilmTmdb(${_mediaType}, ${_tmdbId})`);

      return axios.post('/api/admin/film/update-tmdb-info',{
        film_id: this.film._id,
        tmdb_mediatype: _mediaType,
        tmdb_id: _tmdbId
      })
          .then((response) => {
            console.log(response.data);
            this.film = new Film(response.data);
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
}
</script>


<style scoped>

.page-header {
  margin-bottom: 60px;
}

.monospace {
  font-family: var(--bs-font-monospace);
}


.poster-image {
  float: left;
  margin: 0 15px 0 0;
  width:100px!important;
  height:150px!important;
  object-fit:cover;
}

.language-icon {
  padding-right: 5px;
}

.nomination-list {
  font-weight: 350;
  font-size: small;
//margin-left: 10px;
}

ul {
  padding-left: 10px;
}

li {
  text-align: left;
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0px 0 0px 20px;
  list-style: none;
  background-repeat: no-repeat;
  background-position-x: 0px;
  background-position-y: 4px;
  background-size: 14px;
}

.li_win {
  background-image: url('@/../public/win_icon.svg');
}

.li_nom {
  background-image: url('@/../public/nom_icon.svg');
}

.li_win_bw {
  background-image: url('@/../public/win_icon-bw.svg');
}

.li_nom_bw {
  background-image: url('@/../public/nom_icon-bw.svg');
}


.card {

  .card-film-title {
    font-weight: 500;
    margin-bottom: 1em;
  }

  margin-top: 25px;
  margin-bottom: 25px;

  .online-source-links {
    margin-top: 1em;
    text-align: right;
    a {
      display: block;
    }
  }

  .api-response {
    color: grey;
    font-family: var(--bs-font-monospace);
    margin-top: 2em;

    pre {
      font-size: x-small;
      height: 20em;
      border-width: thin;
      border-style: solid;
      border-color: lightgrey;
      padding: 1em;

    }

  }
}

.card-tmdb {
  border-width: medium;
  border-color: #01b4e4;

  .card-header {
    font-weight: bold;
    color: #01b4e4;
  }

  .btn-update-film {
    color: crimson;
    border-color: crimson;
    float: right;
  }

  .btn-use-tmdb {
    color: #01b4e4;
    border-color: #01b4e4;
    float: right;
  }

}


.card-gracenote {
  border-width: medium;
  border-color: #f40852;

  .card-header {
    font-weight: bold;
    color: #f40852;
  }
}




</style>