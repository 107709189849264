<template>

  <div v-if="isAdmin" class="container">

    <h1 class="text-danger"><i class="fa fa-wrench"></i> Admin Page</h1>
    <hr>


    <div class="admin-menu">

      <router-link to="/admin/users"><i class="fa fa-address-book"></i> users</router-link>
      <router-link to="/admin/new-films"><i class="fa fa-file-video-o"></i> new films</router-link>
    </div>


    <div class="tests">
      <hr>

      <h2><i class="fa fa-wrench"></i> tests</h2>

      <button class="btn btn-sm btn-outline-primary" type="button" @click="testToast">
        <i class="fa comment"></i> test toast
      </button>

      <button class="btn btn-sm btn-outline-primary" type="button" @click="testApiCall">
        <i class="fa fa-paper-plane"></i> test api
      </button>

      <button class="btn btn-sm btn-outline-primary" type="button" @click="testHolidays">
        <i class="fa fa-calendar-o"></i> test holidays
      </button>
    </div>
  </div>
</template>


<script>
//import axios from "axios";
import {userStore} from "@/userStore";
import axios from "axios";
import {EventBus} from "@/eventBus";
import '@/date-utilities';

export default {
  name: "AdminPage",
  components: {},
  props: [],
  data() {
    return {}
  },
  computed: {
    isAdmin() {
      return userStore.isAdmin();
    },
    winIcon() {
      return `${process.env.BASE_URL}win_icon-bw.svg`
    }
  },
  methods: {

    testToast() {
      //EventBus.emit('toast-notification', "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.");
      EventBus.emit('toast-notification', 'Test toast notification.');
      EventBus.emit('toast-notification', 'ANOTHER Test toast notification.');
    },

    async testApiCall() {

      axios.get('api/film/genres')
          //axios.post(`/api/admin/send-test-email/arods.island@gmail.com`)
          .then((response) => {
            console.log(response);
          })
          .catch((err) => {
            console.log(err);
          });
    },

      testHolidays() {

        const testYear = 2023;

        const holidayFunctionsAndEmojis = {
          isNewYearsDay: '\u{1F389}',      // 🎉
          //isMlkDay: '\u{1F64F}',          // 🙏
          //isGroundhogDay: '\u{1F43E}',    // 🐾
          //isWashingtonsBirthday: '\u{1F1FA}\u{1F1F8}', // 🇺🇸
          //isPresidentsDay: '\u{1F3DB}\u{FE0F}',       // 🏛️
          isValentinesDay: '\u{2764}\u{FE0F}',   // ❤️
          //isLeapDay: '\u{1F438}',         // 🐸
          isStPatricksDay: '\u{1F340}',   // 🍀
          isEaster: '\u{1F430}', // 🐰
          isMothersDay: '\u{1F339}',      // 🌹
          isMemorialDay: '\u{1FA96}', // 🪖
          isFathersDay: '\u{1F454}',      // 👔
          //isJunteenth: '\u{1F389}',       // 🎉
          isIndependenceDay: '\u{1F9E8}', // 🧨
          //isLaborDay: '\u{1F4BC}',        // 💼
          //isColumbusDay: '\u{2693}',      // ⚓
          //isIndigenousPeoplesDay: '\u{1F30E}', // 🌎
          isHalloween: '\u{1F383}',       // 🎃
          //isVeteransDay: '\u{1F1FA}\u{1F1F8}', // 🇺🇸
          isThanksgiving: '\u{1F983}',    // 🦃
          //isBlackFriday: '\u{1F6CD}\u{FE0F}', // 🛍️
          isChristmas: '\u{1F384}',       // 🎄
          //isNewYearsEve: '\u{1F55B}'      // 🕛
        };

        let testDate = new Date(testYear, 0, 1);

        while (testDate.getFullYear() === testYear) {

          for (const [funcName, emojiValue] of Object.entries(holidayFunctionsAndEmojis)) {
            if ( testDate[funcName] && testDate[funcName]() ) {
              console.log(`${testDate.toLocaleDateString()} ${funcName} ${emojiValue}`);
            }
          }
          testDate.setDate(testDate.getDate() + 1);
      }
    }
  }
}
</script>


<style scoped>

.admin-menu {
  a {
    text-decoration: inherit;
    display: block;
  }
}

.tests {

  h2 {
    margin-bottom: 1em;
  }

  margin-top: 100px;

  button {
    display: block;
    margin-top: 10px;
  }

}

</style>